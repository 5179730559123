<template>
    <div class="section-form-content">
        <div class="lfont" v-if="recruitment_data">
            <ShowRecruitmentDetail :recruitment_data="recruitment_data" />
            <br>
            <div v-if="show_btn">
                <v-btn class="mx-2 lfont text-white" color="warning" @click="confirm_approve">
                    <v-icon dark>mdi-check</v-icon> ອະນຸມັດ
                </v-btn>
                <v-btn class="mx-2 lfont text-white" color="red" @click="reject_recruitment">
                    <v-icon dark>mdi-close</v-icon> ປະຕິເສດ
                </v-btn>
            </div>
        </div>
        <ModalDelete>
            <template v-slot="{ close }">
                <ConfirmAction @close="close" @confirm="approve_recruitment" />
            </template>
        </ModalDelete>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
import ShowRecruitmentDetail from "@/components/Recruitments/ShowRecruitmentDetail.vue";
import ConfirmAction from '@/components/Recruitments/ConfirmAction';
export default {
    components: {
        Loading,
        ShowRecruitmentDetail,
        ConfirmAction,
    },
    data() {
        return {
            request_id: this.$route.params.id,
            isLoading: false,
            recruitment_data: false,
            show_btn: false,
            server_errors: {
                remarks: ""
            }
        }
    },
    methods: {
        confirm_approve() {
            this.$store.commit("modalDelete_State", true);
        },
        approve_recruitment() {
            this.$store.commit("modalDelete_State", false);
            this.isLoading = true;
            this.$axios.put(`/employee/hr/approve/recruitment/${this.request_id}`).then((res) => {
                if (res.status === 200) {
                    this.$router
                        .push({
                            name: "hr.approve.recruitment",
                            params: {
                                id: this.$route.params.id,
                            },
                        })
                        .catch(() => { });
                }
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                if (error.response.status === 422) {
                    let obj = error.response.data.errors;
                    if (!obj) {
                        obj = error.response.data;
                        let res = "";
                        for (let [key, value] of Object.entries(obj)) {
                            res = value;
                        }
                        this.$notification.ShowErrors("top-right", "danger", 3000, res);
                    } else {
                        for (let [key, value] of Object.entries(obj)) {
                            this.server_errors[key] = value[0];
                            this.$notification.ShowErrors(
                                "top-right",
                                "danger",
                                3000,
                                (this.server_errors[key] = value[0])
                            );
                        }
                    }
                }
            });
        },
        reject_recruitment() {
            this.isLoading = true;
            this.$axios.put(`/employee/director/reject/recruitment/${this.request_id}`).then((res) => {
                if (res.status === 200) {
                    this.$router
                        .push({
                            name: "director.approve.recruitment",
                            params: {
                                id: this.$route.params.id,
                            },
                        })
                        .catch(() => { });
                }
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                if (error.response.status === 422) {
                    let obj = error.response.data.errors;
                    if (!obj) {
                        obj = error.response.data;
                        let res = "";
                        for (let [key, value] of Object.entries(obj)) {
                            res = value;
                        }
                        this.$notification.ShowErrors("top-right", "danger", 3000, res);
                    } else {
                        for (let [key, value] of Object.entries(obj)) {
                            this.server_errors[key] = value[0];
                            this.$notification.ShowErrors(
                                "top-right",
                                "danger",
                                3000,
                                (this.server_errors[key] = value[0])
                            );
                        }
                    }
                }
            });
        },
        fetchRecruitment() {
            this.isLoading = true;
            this.$axios.get(`/employee/recruitment/request/${this.request_id}`).then((res) => {
                if (res.status === 200) {
                    this.recruitment_data = res.data.data;
                    let director_position = res.data.data.approver.filter(item => item.position == 'director')[0];
                    let hr_position = res.data.data.approver.filter(item => item.position == 'hr')[0];
                    this.show_btn = hr_position.status == 'approved' || director_position.status == 'pending' ? false : true;
                }
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                if (error.response.status === 422) {
                    let obj = error.response.data.errors;
                    if (!obj) {
                        obj = error.response.data;
                        let res = "";
                        for (let [key, value] of Object.entries(obj)) {
                            res = value;
                        }
                        this.$notification.ShowErrors("top-right", "danger", 3000, res);
                    } else {
                        for (let [key, value] of Object.entries(obj)) {
                            this.server_errors[key] = value[0];
                            this.$notification.ShowErrors(
                                "top-right",
                                "danger",
                                3000,
                                (this.server_errors[key] = value[0])
                            );
                        }
                    }
                }
            });
        }
    },
    created() {
        this.fetchRecruitment();
    },
}
</script>

<style lang="scss" scoped>
.table {
    td {
        border: 1px solid white !important;
    }
}
</style>