<template>
    <div class="container-delete">
        <div class="main-title">
            <h1> <span><i class="far fa-check-circle" style="color: #0046f8"></i></span> ອະນຸມັດຂໍພະນັກງານ</h1>
        </div>
        <div class="content-card-layout">
            <div class="main-content-card">
                <h4 class="ml-2 mb-10" style="font-weight: 400;color:red;"></h4>
            </div>
        </div>
        <div class="section-footer-delete">
            <div class="btn-section">
                <v-btn class="btn-action cancel" @click="$emit('confirm')">
                    ຕົກລົງ
                </v-btn>
                <v-btn class="btn-cancel lfont" outlined @click="$emit('close')">
                    ຍົກເລີກ
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.container-delete {
    width: 100%;
    height: 180px;

    .main-title h1 {
        font-size: 26px;
        font-weight: bold;
        margin-left: 30px;
    }
}
</style>